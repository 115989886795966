@font-face {
  font-family: FiraSans;
  src: url(assets/Fira_Sans/FiraSans-Light.ttf);
  font-weight: 800;
}

@font-face {
  font-family: PlexSans;
  src: url(assets/IBM_Plex_Sans/IBMPlexSans-Bold.ttf);
}

body {
  background-color: EBCBFB;
  text-align: center;
  font-size: 20px;
  padding: 2.5%;
  font-family: 'FiraSans';
}

h1, h2, h3, h4, h5 {
  font-family: 'PlexSans';
}

.profile {
  width: 180px;
  height: 240px;
  border-radius: 100px;
}

.title-fade-out {
  opacity: 0;
  animation-name: fade-out;
  animation-duration: 1.5s;
}

.title-fade-in {
  opacity: 100;
  animation-name: fade-in;
  animation-duration: 1.5s;
}

@keyframes fade-out {
  from {opacity: 100}
  to {opacity: 0}
}

@keyframes fade-in {
  from {opacity: 0}
  to {opacity: 100}
}